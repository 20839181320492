@charset "utf-8"

$primary: #432391
$link: #F574B9

@import "../node_modules/bulma/sass/base/_all.sass"
@import "../node_modules/bulma/sass/helpers/_all.sass"
@import "../node_modules/bulma/sass/utilities/_all.sass"
@import "../node_modules/bulma/sass/components/level.sass"
@import "../node_modules/bulma/sass/elements/button.sass"
@import "../node_modules/bulma/sass/elements/title.sass"
@import "../node_modules/bulma/sass/grid/_all.sass"

\:root
  --primary-color: #{$primary}
  --link-color: #{$link}
  --main-image-size: 256px

.hero-background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100vh
  background-image: linear-gradient(64.5deg,  var(--link-color), var(--primary-color) 88.7%)

.has-text-primary-dark
    color: #4612c4 !important

.hero-image
  width: inherit
  height: inherit
  position: absolute
  background: url('assets/background.jpg') no-repeat center center fixed
  background-size: cover
  opacity: .4

.app
  min-width: 320px
  height: 100vh
  width: 100%
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center

  .center-box
    position: relative
    transition: opacity .5s ease-in
    opacity: 0

  .center-box.is-active
    opacity: 1

  .divider
    height: 1px
    width: percentage(1/3)
    background-color: var(--primary-color)
    box-shadow: 0 1px 0 rgba(255, 255, 255, .15)

  .logo-container
    width: var(--main-image-size)
    height: var(--main-image-size)
    border-radius: 100%
    overflow: hidden
    box-shadow: 0 0 12px #260c5b

  .description
    max-width: 320px

  .logo
    background-repeat: no-repeat
    background-position: center center
    background-size: var(--main-image-size) var(--main-image-size)
    background-image: url('assets/profile.png')
    width: inherit
    height: inherit

  .button
    box-shadow: 0 0 12px #260c5b

@include mobile
  .app
    .level
      display: flex
      flex-flow: column

    .level-left
      order: 2

    .level-right
      order: 1

    .logo-container
      margin-left: 0 !important
      margin-bottom: 1.5rem

    .divider
      margin: 0 percentage(1/3)
